// import PhotoAlbum from "react-photo-album";

// Hooks
import { usePages } from "../../hooks/use-pages";

// Images
import Logo from "../../images/logo-full-white.svg";

// components
import { Text } from "../../design_system/components";
import BodsImage from "../../design_system/components/image/BodsImage";

// Styles
import "./PrivateEventsMenuPage.scss";
import { MenuTypeType } from "../../types/Menu.type";
import PrivateEventMenuItem from "./PrivateEventMenuItem";

const PrivateEventsMenuPage = () => {
  const { menu } = usePages({
    path: "private-event-menu",
  });

  const beer = menu.filter((item) => item.type === MenuTypeType.beer);
  const wine = menu.filter((item) => item.type === MenuTypeType.wine);
  const na = menu.filter((item) => item.type === MenuTypeType.non_alcholic);

  const baseClassName = "PrivateEventsMenuPage";

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__logo`}>
        <BodsImage src={Logo} />
      </div>

      {beer.length > 0 && (
        <>
          <Text tag="h2" bold>
            Beer
          </Text>
          <div className={`${baseClassName}__menu`}>
            {beer.map((item) => (
              <PrivateEventMenuItem item={item} key={item.sys.id} />
            ))}
          </div>
        </>
      )}
      {wine.length > 0 && (
        <>
          <Text tag="h2" bold>
            Wine
          </Text>
          <div className={`${baseClassName}__menu`}>
            {wine.map((item) => (
              <PrivateEventMenuItem item={item} key={item.sys.id} />
            ))}
          </div>
        </>
      )}
      {na.length > 0 && (
        <>
          <Text tag="h2" bold>
            Non-Alcoholic
          </Text>
          <div className={`${baseClassName}__menu-slim`}>
            {na.map((item) => (
              <div className={`${baseClassName}__menu-slim__item`}>
                {item.name}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default PrivateEventsMenuPage;
