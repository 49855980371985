export enum MenuTypeType {
  beer = "beer",
  wine = "wine",
  non_alcholic = "non-alcoholic",
}

export type MenuType = {
  name: string;
  description?: string;
  abv?: number;
  type: MenuTypeType;
  sys: {
    id: string;
  };
};
