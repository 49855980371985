export type RouteType = {
  path: string;
  label: string;
  exact: boolean;
  showTitle: boolean;
};

interface RoutesArray {
  [key: string]: RouteType;
}

const Routes: RoutesArray = {
  HOME_PAGE: {
    path: "/",
    label: "Home",
    exact: true,
    showTitle: false,
  },
  EVENTS_PAGE: {
    path: "/events",
    label: "Events",
    exact: false,
    showTitle: true,
  },
  EVENT_DETAILS_PAGE: {
    path: "/events/:id",
    label: "Event Details",
    exact: false,
    showTitle: false,
  },
  TAPROOM_PAGE: {
    path: "/taproom",
    label: "Taproom",
    exact: false,
    showTitle: false,
  },
  BEER_PAGE: {
    path: "/beer",
    label: "Beer",
    exact: false,
    showTitle: false,
  },
  CONTACT_PAGE: {
    path: "/contact",
    label: "Contact",
    exact: false,
    showTitle: true,
  },
  FOOD_TRUCK_PAGE: {
    path: "/foodtrucks",
    label: "Food Trucks",
    exact: true,
    showTitle: true,
  },
  // FOOD_TRUCK_DETAILS_PAGE: {
  //   path: "/foodtrucks/:id",
  //   label: "Food Trucks",
  //   exact: true,
  //   showTitle: false,
  // },
  JOBS_PAGE: {
    path: "/jobs",
    label: "Jobs",
    exact: false,
    showTitle: false,
  },
  JOBS_DETAILS_PAGE: {
    path: "/jobs/:id",
    label: "Job Details",
    exact: false,
    showTitle: false,
  },
  ABOUT_PAGE: {
    path: "/about",
    label: "About",
    exact: false,
    showTitle: false,
  },
  STORE_PAGE: {
    path: "/store",
    label: "Store",
    exact: false,
    showTitle: false,
  },
  PRIVATE_EVENTS_PAGE: {
    path: "/private-events",
    label: "Private Events",
    exact: false,
    showTitle: false,
  },
  PRIVATE_EVENTS_MENU_PAGE: {
    path: "/private-event-menu",
    label: "On Tap",
    exact: true,
    showTitle: true,
  },
  DESIGN_DOCS_PAGE: {
    path: "/design",
    label: "Design",
    exact: false,
    showTitle: false,
  },
};

export { Routes };
