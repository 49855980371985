import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// Constants
import { Routes } from "./constants/Routes.constants";

// Components
import ScrollToTopOnRouteChange from "./components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import RouteWrapper from "./components/RouteWrapper/RouteWrapper";
import HomePage from "./components/HomePage/HomePage";
import EventsPage from "./components/EventsPage/EventsPage";
import ContactPage from "./components/ContactPage/ContactPage";
import FoodTruckPage from "./components/FoodTruckPage/FoodTruckPage";
import TaproomPage from "./components/TaproomPage/TaproomPage";
import AboutPage from "./components/AboutPage/AboutPage";
import JobsPage from "./components/JobsPage/JobsPage";
import StorePage from "./components/StorePage/StorePage";
import DesignPage from "./components/DesignPage/DesignPage";
import NotFoundPage from "./components/NotFoundPage/NotFoundPage";
import EventDetailsPage from "./components/EventDetailsPage/EventDetailsPage";
import Over21ConfirmationModal from "./components/Over21ConfirmationModal/Over21ConfirmationModal";
import GoogleAnalytics from "./components/GoogleAnalytics/GoogleAnalytics";
import Rollbar from "./components/Rollbar/Rollbar";
import BeerPage from "./components/BeerPage/BeerPage";
import PrivateEventsPage from "./components/PrivateEventsPage/PrivateEventsPage";
import PrivateEventsMenuPage from "./components/PrivateEventsMenuPage/PrivateEventsMenuPage";

// Styles
import "./App.scss";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60, // 1 hour
    },
  },
});

const App = () => {
  return (
    <Rollbar>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Over21ConfirmationModal />
          <GoogleAnalytics />
          <ScrollToTopOnRouteChange />
          <Switch>
            <RouteWrapper
              exact
              path={Routes.EVENTS_PAGE.path}
              component={EventsPage}
            />
            <RouteWrapper
              path={Routes.EVENT_DETAILS_PAGE.path}
              component={EventDetailsPage}
            />
            <RouteWrapper
              path={Routes.CONTACT_PAGE.path}
              component={ContactPage}
            />
            <RouteWrapper path={Routes.BEER_PAGE.path} component={BeerPage} />
            <RouteWrapper
              path={Routes.TAPROOM_PAGE.path}
              component={TaproomPage}
            />
            <RouteWrapper path={Routes.ABOUT_PAGE.path} component={AboutPage} />
            <RouteWrapper
              path={[Routes.JOBS_PAGE.path, Routes.JOBS_DETAILS_PAGE.path]}
              component={JobsPage}
              exact
            />
            <RouteWrapper path={Routes.STORE_PAGE.path} component={StorePage} />
            {/* <RouteWrapper
            path={[Routes.FOOD_TRUCK_DETAILS_PAGE.path]}
            component={FoodTruckDetailsPage}
            exact
          /> */}
            <RouteWrapper
              path={[Routes.FOOD_TRUCK_PAGE.path]}
              component={FoodTruckPage}
              exact
            />
            <RouteWrapper
              path={Routes.HOME_PAGE.path}
              component={HomePage}
              exact={true}
            />
            <RouteWrapper
              path={Routes.DESIGN_DOCS_PAGE.path}
              component={DesignPage}
              exact={true}
            />
            <RouteWrapper
              path={Routes.PRIVATE_EVENTS_PAGE.path}
              component={PrivateEventsPage}
            />
            <Route
              path={Routes.PRIVATE_EVENTS_MENU_PAGE.path}
              component={PrivateEventsMenuPage}
            />
            <RouteWrapper path="*" component={NotFoundPage} />
          </Switch>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Rollbar>
  );
};

export default App;
