import { MenuType } from "../../types/Menu.type";

import { Text } from "../../design_system/components";

// Styles
import "./PrivateEventMenuItem.scss";

type Props = {
  item: MenuType;
};

const PrivateEventMenuItem = ({ item }: Props) => {
  const baseClassName = "PrivateEventMenuItem";
  return (
    <div className={baseClassName}>
      <Text className={`${baseClassName}__title`}>
        {item.name}
        {item.abv && (
          <>
            <div className={`${baseClassName}__title__dot`} />
            <Text className={`${baseClassName}__title__abv`} tag="span">
              {item.abv}% ABV
            </Text>
          </>
        )}
      </Text>
      {item.description && (
        <Text className={`${baseClassName}__desc`}>{item.description}</Text>
      )}
    </div>
  );
};

export default PrivateEventMenuItem;
